import React from 'react';
import Navbar from '../Components/DefNavbar'; 
import Sidebar from '../Components/Sidebar';
import TopicsGraph from '../Components/TopicsGraph';
import './Explore.css';

const Explore = () => {
  const toggleSidebar = () => {
    const sidebar = document.querySelector('.sidebar-container');
    if (sidebar) {
      sidebar.classList.toggle('show');
    }
  };

  return (
    <div className="explore-container">
      <div className="explore-navbar-container">
        <div><Navbar /></div>
      </div>
      <div className="menu-button">
        <button className="menu-toggle" onClick={toggleSidebar}>
          <span style={{
            fontSize: '16px',
            transform: 'scaleX(0.8)',
            fontWeight: '600',
            color: 'var(--text-color-button)'
          }}>
            ›
          </span>
        </button>
      </div>
      <Sidebar />
      <div className="main-content">
        <TopicsGraph />
      </div>
    </div>
  );
};

export default Explore;