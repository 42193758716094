import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import * as dagreD3 from 'dagre-d3';
import dagre from 'dagre';
import topicsData from '../data/TopicsStructure.json';
import './TopicsGraph.css';
// Import MUI icons
import SchoolIcon from '@mui/icons-material/School';
import ScienceIcon from '@mui/icons-material/Science';
import QuizIcon from '@mui/icons-material/Quiz';
import Tooltip from '@mui/material/Tooltip';
import ReactDOM from 'react-dom';

const TopicsGraph = () => {
    const svgRef = useRef();
    const defsRef = useRef(null);
    const graphRef = useRef(null);  // Store graph instance
    const renderRef = useRef(null); // Store renderer instance
    const gRef = useRef(null);      // Store container group
    const [expandedNode, setExpandedNode] = useState(null);

    // Define constants at component level
    const EXPANDED_WIDTH = 280;
    const PADDING = 30;
    const LINE_HEIGHT = 20;
    const TITLE_TOP_MARGIN = 40;
    const TITLE_BOTTOM_MARGIN = 30;

    // Move createGradientDef to the top
    const createGradientDef = (defs, id, colors) => {
        const gradient = defs.append("linearGradient")
            .attr("id", id)
            .attr("x1", "0%")
            .attr("y1", "0%")
            .attr("x2", "100%")
            .attr("y2", "0%");

        // Create color stops with smooth transitions
        colors.forEach((color, index) => {
            gradient.append("stop")
                .attr("offset", `${(index * 100) / (colors.length - 1)}%`)
                .attr("stop-color", color);
        });

        // Add animation
        gradient.append("animateTransform")
            .attr("attributeName", "gradientTransform")
            .attr("type", "rotate")
            .attr("from", "0 0.5 0.5")
            .attr("to", "360 0.5 0.5")
            .attr("dur", "8s")
            .attr("repeatCount", "indefinite");
    };

    // Define button configurations
    const nodeButtons = [
        { icon: SchoolIcon, tooltip: "Course", action: (nodeId) => console.log("Course clicked for", nodeId) },
        { icon: ScienceIcon, tooltip: "Sandbox", action: (nodeId) => console.log("Sandbox clicked for", nodeId) },
        { icon: QuizIcon, tooltip: "Exam", action: (nodeId) => console.log("Exam clicked for", nodeId) }
    ];

    // Update the calculateNodeDimensions function to use the constants
    const calculateNodeDimensions = (label, description, isExpanded) => {
        if (!isExpanded) {
            const words = label.toUpperCase().split(' ');
            const maxLineLength = Math.max(...words.map(word => word.length));
            const lineCount = words.length;
            return {
                width: Math.max(maxLineLength * 12 + 48, 140),
                height: lineCount * 24 + 24
            };
        }

        // For expanded state, calculate height based on content
        const titleWidth = getTextWidth(label.toUpperCase(), '14px Inter');
        const titleLines = titleWidth > (EXPANDED_WIDTH - PADDING * 2) ? 2 : 1;
        const descriptionLines = Math.ceil((description.length * 7) / (EXPANDED_WIDTH - PADDING * 2));

        return {
            width: EXPANDED_WIDTH,
            height: TITLE_TOP_MARGIN +
                titleLines * LINE_HEIGHT +
                TITLE_BOTTOM_MARGIN +
                descriptionLines * LINE_HEIGHT +
                PADDING +
                60
        };
    };

    // Update the wrapText function to handle fixed width better
    const wrapText = (text, str, width) => {
        const words = str.split(/\s+/);
        let line = [];
        let lineNumber = 0;
        const lineHeight = 1.2;
        let currentLine = '';

        words.forEach(word => {
            const testLine = currentLine + (currentLine ? ' ' : '') + word;
            const testWidth = getTextWidth(testLine, '12px Inter');

            if (testWidth > width) {
                // Add current line
                text.append('tspan')
                    .attr('x', 0)
                    .attr('dy', lineNumber === 0 ? 0 : `${lineHeight}em`)
                    .text(currentLine);

                currentLine = word;
                lineNumber++;
            } else {
                currentLine = testLine;
            }
        });

        // Add remaining text
        if (currentLine) {
            text.append('tspan')
                .attr('x', 0)
                .attr('dy', lineNumber === 0 ? 0 : `${lineHeight}em`)
                .text(currentLine);
        }
    };

    // Add helper function to calculate text width
    const getTextWidth = (text, font) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        return context.measureText(text).width;
    };

    // Update the nodeShape function
    const nodeShape = (parent, bbox, node) => {
        const nodeEl = parent.append('g');
        const isExpanded = node.expanded;

        // Get color scheme for the node
        const scheme = topicsData.colorSchemes[node.colorScheme] || topicsData.colorSchemes.elementary;
        const gradientId = `gradient-${node.id}`;

        // Create gradient definition if it doesn't exist
        if (!document.getElementById(gradientId) && defsRef.current) {
            createGradientDef(defsRef.current, gradientId, scheme.colors);
        }

        // Add gradient border
        nodeEl.append('rect')
            .attr('class', 'node-gradient-border')
            .attr('rx', 8)
            .attr('ry', 8)
            .attr('x', -bbox.width / 2 - 1)
            .attr('y', -bbox.height / 2 - 1)
            .attr('width', bbox.width + 2)
            .attr('height', bbox.height + 2)
            .style('stroke', `url(#${gradientId})`)
            .style('stroke-width', '2px');

        // Add white rectangle with updated dimensions
        nodeEl.append('rect')
            .attr('class', 'node-rect')
            .attr('rx', 8)
            .attr('ry', 8)
            .attr('x', -bbox.width / 2)
            .attr('y', -bbox.height / 2)
            .attr('width', bbox.width)
            .attr('height', bbox.height);

        // Add label with conditional positioning
        const label = nodeEl.append('text')
            .attr('class', 'node-label')
            .attr('text-anchor', 'middle')
            .attr('dy', isExpanded ? 0 : '0.35em');

        if (!isExpanded) {
            // Non-expanded state: Handle multiline titles as before
            const words = node.label.toUpperCase().split(' ');
            let line = [];
            let lineNumber = 0;
            const lineHeight = 1.3;
            words.forEach(word => {
                line.push(word);
                label.append('tspan')
                    .attr('x', 0)
                    .attr('dy', lineNumber === 0 ? -((words.length - 1) * lineHeight) / 2 + 'em' : lineHeight + 'em')
                    .text(line.join(' '));
                line = [];
                lineNumber++;
            });
        } else {
            // Single line title
            label.append('tspan')
                .attr('x', 0)
                .text(node.label.toUpperCase());

            // Position the label at the top of the expanded node with proper spacing
            label.attr('transform', `translate(0, ${-bbox.height / 2 + TITLE_TOP_MARGIN})`);
        }

        // Add expanded content if node is expanded
        if (isExpanded) {
            // Add description with more space from title
            const description = nodeEl.append('text')
                .attr('class', 'node-description')
                .attr('text-anchor', 'middle')
                .attr('transform', `translate(0, ${-bbox.height / 2 + 100})`);

            // Wrap description text with fixed width
            wrapText(description, node.description, bbox.width - 60);

            // Add buttons at the bottom (position stays the same)
            const buttonGroup = nodeEl.append('g')
                .attr('class', 'node-buttons')
                .attr('transform', `translate(0, ${bbox.height / 2 - 35})`);

            nodeButtons.forEach((btn, index) => {
                const button = buttonGroup.append('g')
                    .attr('class', 'node-button')
                    .attr('transform', `translate(${(index - 1) * 56}, 0)`)
                    .on('click', (event) => {
                        event.stopPropagation();
                        btn.action(node.id);
                    });

                // Add button background
                button.append('rect')
                    .attr('class', 'button-background')
                    .attr('x', -22)
                    .attr('y', -22)
                    .attr('width', 44)
                    .attr('height', 44)
                    .attr('rx', 8)
                    .attr('ry', 8);

                // Create a foreignObject for the icon
                const foreignObject = button.append('foreignObject')
                    .attr('class', 'button-icon')
                    .attr('x', -12)
                    .attr('y', -12)
                    .attr('width', 24)
                    .attr('height', 24);

                // Create a div container for the icon
                const iconContainer = foreignObject.append('xhtml:div')
                    .style('width', '100%')
                    .style('height', '100%')
                    .style('display', 'flex')
                    .style('align-items', 'center')
                    .style('justify-content', 'center');

                // Create and render the icon
                const IconComponent = btn.icon;
                ReactDOM.render(
                    <IconComponent style={{
                        width: '20px',
                        height: '20px',
                        fill: '#666'
                    }} />,
                    iconContainer.node()
                );

                // Add tooltip
                button.append('title')
                    .text(btn.tooltip);
            });
        }

        return nodeEl;
    };

    // Add click handler for nodes
    const handleNodeClick = (nodeId) => {
        setExpandedNode(prevExpanded => {
            const newExpanded = prevExpanded === nodeId ? null : nodeId;

            const graph = graphRef.current;
            if (!graph) return newExpanded;

            // Update all nodes
            topicsData.nodes.forEach(node => {
                const isExpanded = node.id === newExpanded;
                const dimensions = calculateNodeDimensions(
                    node.label,
                    node.description,
                    isExpanded
                );

                const currentNode = graph.node(node.id);
                graph.setNode(node.id, {
                    ...currentNode,
                    width: dimensions.width,
                    height: dimensions.height,
                    expanded: isExpanded,
                    intersect: currentNode.intersect
                });
            });

            // Re-run layout
            dagre.layout(graph);

            if (gRef.current) {
                const g = gRef.current;

                // Update nodes and edges together
                const t = d3.transition().duration(300);

                // Update node positions
                g.selectAll('g.node')
                    .transition(t)
                    .attr('transform', function (v) {
                        const node = graph.node(v);
                        return `translate(${node.x}, ${node.y})`;
                    });

                // Update edge paths
                g.selectAll('g.edgePath path')
                    .transition(t)
                    .attr('d', function (e) {
                        const edge = graph.edge(e.v, e.w);
                        const points = edge.points;
                        const line = d3.line()
                            .x(d => d.x)
                            .y(d => d.y)
                            .curve(d3.curveBasis);
                        return line(points);
                    });
            }

            // Re-render the graph
            if (renderRef.current && gRef.current) {
                renderRef.current(gRef.current, graph);
            }

            return newExpanded;
        });
    };

    useEffect(() => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        // Clear any existing SVG content
        d3.select(svgRef.current).selectAll("*").remove();

        // Create SVG with zoom support
        const svg = d3.select(svgRef.current)
            .attr('width', width)
            .attr('height', height)
            .attr('cursor', 'grab');

        // Create a container group for all content
        const g = svg.append('g');

        // Create a new dagre graph
        const graph = new dagre.graphlib.Graph({ compound: true })
            .setGraph({
                rankdir: 'TB',
                nodesep: 70,
                ranksep: 100,
                edgesep: 50,
                marginx: 20,
                marginy: 20
            })
            .setDefaultEdgeLabel(() => ({}));

        // Add nodes to the graph with initial expanded state
        topicsData.nodes.forEach(node => {
            const dimensions = calculateNodeDimensions(node.label, node.description, false);
            graph.setNode(node.id, {
                ...node, // Include all node data
                width: dimensions.width,
                height: dimensions.height,
                expanded: false,
                label: node.label, // Ensure label is passed
                // Add intersection function for edge connections
                intersect: function (point) {
                    // Get the node's bounding box
                    const w = this.width / 2;
                    const h = this.height / 2;

                    // Calculate relative position of the point
                    const dx = point.x - this.x;
                    const dy = point.y - this.y;

                    // Calculate the angle of the point relative to the center
                    const theta = Math.atan2(dy, dx);

                    // Calculate intersection with rounded rectangle
                    const x = Math.cos(theta);
                    const y = Math.sin(theta);

                    // Find the intersection point
                    let intersectX, intersectY;

                    if (Math.abs(y) * w > Math.abs(x) * h) {
                        // Intersects top or bottom
                        intersectX = (h * x) / Math.abs(y);
                        intersectY = h * Math.sign(y);
                    } else {
                        // Intersects left or right
                        intersectX = w * Math.sign(x);
                        intersectY = (w * y) / Math.abs(x);
                    }

                    return {
                        x: this.x + intersectX,
                        y: this.y + intersectY
                    };
                }
            });
        });

        // Add edges to the graph
        topicsData.links.forEach(link => {
            graph.setEdge(link.source, link.target, {
                curve: d3.curveBasis,
                lineInterpolate: 'basis',
                arrowhead: 'vee',
                arrowheadStyle: "fill: #dee2e6",
                style: "stroke-width: 1.5px;",
                labelpos: 'c'
            });
        });

        // Run the dagre layout
        dagre.layout(graph);

        // Create the renderer
        const render = new dagreD3.render();

        // Configure edge path
        render.edgePath = function (parent, e, edge) {
            const path = parent.insert("path", ":first-child")
                .attr("class", "edgePath")
                .attr("marker-end", "url(#arrowhead)");

            return path;
        };

        // Add arrow marker definition and store defs reference
        const defs = svg.append("defs");
        defsRef.current = defs;

        defs.append("marker")
            .attr("id", "arrowhead")
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 8)
            .attr("refY", 0)
            .attr("markerWidth", 8)
            .attr("markerHeight", 8)
            .attr("orient", "auto")
            .append("path")
            .attr("d", "M0,-5L10,0L0,5")
            .attr("class", "arrowhead");

        // Set up zoom behavior
        const zoom = d3.zoom()
            .scaleExtent([0.2, 2])
            .on('zoom', (event) => {
                g.attr('transform', event.transform);
            })
            .on('start', () => svg.attr('cursor', 'grabbing'))
            .on('end', () => svg.attr('cursor', 'grab'));

        svg.call(zoom);

        // Override the default node shape
        render.shapes().rect = nodeShape;

        // Run the renderer
        render(g, graph);

        // Center the graph
        const graphWidth = graph.graph().width || 0;
        const graphHeight = graph.graph().height || 0;
        const initialScale = Math.min(
            width / (graphWidth + 100),
            height / (graphHeight + 100)
        );
        const initialTransform = d3.zoomIdentity
            .translate(
                (width - graphWidth * initialScale) / 2,
                (height - graphHeight * initialScale) / 2
            )
            .scale(initialScale * 0.9);
        svg.call(zoom.transform, initialTransform);

        // Add tooltips
        g.selectAll('g.node')
            .append('title')
            .text(v => graph.node(v).description);

        // Handle window resize
        const handleResize = () => {
            svg
                .attr('width', window.innerWidth)
                .attr('height', window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Store references
        graphRef.current = graph;
        renderRef.current = render;
        gRef.current = g;

        // Add click handlers to nodes
        g.selectAll('g.node')
            .on('click', function (event, v) {
                handleNodeClick(v);
                event.stopPropagation();
            });

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="topics-graph-container">
            <svg ref={svgRef}></svg>
        </div>
    );
};

export default TopicsGraph;
