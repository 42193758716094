import React from 'react';
import './MenuNavbar.css';
import ProfileButton from './ProfileButton'


function Navbar() {
  return (
    <div className="navbar">
      <a href="#intro" className="navbar-link">Intro</a>
      <a href="#contribute" className="navbar-link">Contribute</a>
      <ProfileButton/>
    </div>
  );
}

export default Navbar;