// src/Components/ExploreButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ExploreButton.css';

const ExploreButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/explore');
  };

  return (
    <button
      className="explore-button"
      onClick={handleClick}
    >
      Explore
    </button>
  );
};

export default ExploreButton;
