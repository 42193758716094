import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import './Searchbar.css';

const Searchbar = () => {
  const placeholders = useMemo(() => [
    "Theory of Relativity",
    "Quantum Mechanics"
    //... other placeholders
  ], []);
  
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    let typingSpeed = isDeleting ? 40 : 50;

    if (!isDeleting && displayText === placeholders[placeholderIndex]) {
      typingSpeed = 2000;
      setTimeout(() => setIsDeleting(true), typingSpeed);
    } else if (isDeleting && displayText === '') {
      setIsDeleting(false);
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }

    const handleTyping = () => {
      setDisplayText((prevText) => {
        if (isDeleting) {
          return prevText.slice(0, -1);
        } else {
          return placeholders[placeholderIndex].slice(0, prevText.length + 1);
        }
      });
    };

    const typingTimer = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(typingTimer);
  }, [displayText, isDeleting, placeholders, placeholderIndex]);

  const handleSearch = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!query) return;

      try {
        const response = await axios.post('http://localhost:5000/api/search', { query });
        setSearchResults(response.data);
      } catch (error) {
        console.error('Error fetching search results:', error);
        alert(`Error: ${error.response ? error.response.data.error : error.message}`);
      }
    }
  };

  return (
    <div className="searchbar-container">
      <input
        type="text"
        className="searchbar"
        placeholder={displayText}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleSearch}
      />
      {searchResults && (
        <div className="search-results">
          <pre>{JSON.stringify(searchResults, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default Searchbar;
